.login-form {
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
  
  .content {
      display: grid;
      background-color: bisque;
      height: 100vh;
      place-items: center;
    }
  