.fix-form .ant-row {
    margin-bottom: -2px;
}
.ant-select-item-option-active:not( .ant-select-item-option-disabled){background-color:#40a9ff!important;}

.ant-table-content {
    font-size: 12px!important;
}
.ant-form label  {  font-size: 12px!important;
}

.fix-table-list-job .ant-table-tbody > tr > td {
    padding: 5px 16px!important;

}

.fix-table-list-job .ant-table-tbody > tr > td:hover{
    cursor: pointer;
}